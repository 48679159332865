// ---
const hamMenuBtn = document.querySelector('.header__main-ham-menu-cont')
const smallMenu = document.querySelector('.header__sm-menu')
const headerHamMenuBtn = document.querySelector('.header__main-ham-menu')
const headerHamMenuCloseBtn = document.querySelector(
  '.header__main-ham-menu-close'
)
const headerSmallMenuLinks = document.querySelectorAll('.header__sm-menu-link')

hamMenuBtn.addEventListener('click', () => {
  if (smallMenu.classList.contains('header__sm-menu--active')) {
    smallMenu.classList.remove('header__sm-menu--active')
  } else {
    smallMenu.classList.add('header__sm-menu--active')
  }
  if (headerHamMenuBtn.classList.contains('d-none')) {
    headerHamMenuBtn.classList.remove('d-none')
    headerHamMenuCloseBtn.classList.add('d-none')
  } else {
    headerHamMenuBtn.classList.add('d-none')
    headerHamMenuCloseBtn.classList.remove('d-none')
  }
})

for (let i = 0; i < headerSmallMenuLinks.length; i++) {
  headerSmallMenuLinks[i].addEventListener('click', () => {
    smallMenu.classList.remove('header__sm-menu--active')
    headerHamMenuBtn.classList.remove('d-none')
    headerHamMenuCloseBtn.classList.add('d-none')
  })
}

// ---
const headerLogoConatiner = document.querySelector('.header__logo-container')

headerLogoConatiner.addEventListener('click', () => {
  location.href = '/'
})

const currentYear = document.getElementById('currentYear');
currentYear.innerText = (new Date()).getFullYear().toString(10);

document.forms.contactForm.addEventListener('submit', async (event) => {
  event.preventDefault();

  const contactForm = new FormData(document.forms.contactForm);
  const sendData = Object.fromEntries(contactForm);
  const resultMessage = document.getElementById('resultMessage');
  resultMessage.innerText = '';

  try {
    let response = await fetch(process.env.FORM_SUBMIT_URL, {
      method: 'POST',
      mode: 'cors',
      // credentials: 'include',
      // Below doesn't work because of no-cors mode :(
      // headers: {
      //   'Content-Type': 'application/json',
      //   // 'Authorization': `Bearer ${process.env.YA_TOKEN}`
      // },
      body: JSON.stringify(sendData)
    });

    const result = await response.json();

    resultMessage.innerText = result.message;

    if (result.success) {
      resultMessage.classList.add('contact__form-message-success');
      resultMessage.classList.remove('contact__form-message-error');
    } else {
      resultMessage.classList.add('contact__form-message-error');
      resultMessage.classList.remove('contact__form-message-success');
    }

  } catch (e) {
    resultMessage.innerText = 'Something went wrong. Please, try again later.';
    resultMessage.classList.add('contact__form-message-error');
    resultMessage.classList.remove('contact__form-message-success');
  }

});

particlesJS('particles', {
  particles: {
    number: {
      value: 50,
      density: { enable: 1, value_area: 800 }
    },
    color: { value: "#ffffff" },
    shape: {
      type: "polygon",
      stroke: { width: 0, color: "#000000" },
      polygon: { nb_sides: 5 }
    },
    opacity: { value: .5, random: 1, anim: { enable: 0, speed: 1, opacity_min: .1, sync: !1 } },
    size: { value: 3, random: 1, anim: { enable: 0, speed: 19.18081918081918, size_min: .1, sync: 0 } },
    line_linked: { enable: 1, distance: 150, color: "#ffffff", opacity: .4, width: 1 },
    move: {
      enable: 1,
      speed: 4,
      direction: "none",
      random: 1,
      straight: 0,
      out_mode: "bounce",
      bounce: 0,
      attract: { enable: 0, rotateX: 600, rotateY: 1200 }
    },
    nb: 80
  },
  interactivity: {
    detect_on: "canvas",
    events: { onhover: { enable: 0, mode: "grab" }, onclick: { enable: 1, mode: "push" }, resize: 1 },
    modes: {
      grab: { distance: 400, line_linked: { opacity: 0.75 } },
      bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
      repulse: { distance: 200, duration: .4 },
      push: { particles_nb: 4 },
      remove: { particles_nb: 2 }
    }
  },
  retina_detect: 1
});

function addRecaptchaScript() {
  const script = document.createElement("script");
  script.defer = true;
  script.async = true;
  script.src = "https://www.google.com/recaptcha/api.js";
  document.body.appendChild(script);

  // Removing event listeners
  document.querySelectorAll('.contact__form-input')
    .forEach((node) => node.removeEventListener('focus', addRecaptchaScript));
}

document.querySelectorAll('.contact__form-input')
  .forEach((node) => node.addEventListener('focus', addRecaptchaScript));
